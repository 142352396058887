<template>
    <modal ref="crearCategoria" v-loading="loading" :titulo="titulo" icon="categoria" no-aceptar adicional="Guardar" tamano="modal-lg" @adicional="insertCategoria">
        <ValidationObserver ref="validacion">
            <div class="row mx-0">
                <div class="col-8">
                    <div class="row mx-0 my-3 justify-center">
                        <div class="col">
                            <ValidationProvider v-slot="{errors}" rules="required|max:30" name="Nombre" class="d-flex flex-column">
                                <label class="ml-2 text-general f-13">Nombre de la categoría</label>
                                <el-input v-model="model.nombre" maxlength="30" show-word-limit size="small" placeholder="Nombre" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 my-3 justify-center">
                        <div class="col">
                            <ValidationProvider v-slot="{errors}" rules="max:500" name="Descripción" class="d-flex flex-column">
                                <label class="ml-2 text-general f-13">Descripción de la categoría</label>
                                <el-input v-model="model.descripcion" type="textarea" :autosize="{ minRows: 4, maxRows: 6}" placeholder="Descripción" size="small" maxlength="500" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 my-3 justify-center">
                        <div class="col-10 ml-4">
                            <el-checkbox v-model="model.adultos" class="text-general f-100">
                                Categoría para mayores de edad
                            </el-checkbox>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row mx-0 d-flex flex-column justify-content-center align-items-center mx-0 my-3">
                        <div class="col-auto text-center text-right">
                            <slim-cropper ref="cropCategoria" :options="slimOptions" class="border cr-pointer br-10" style="height:200px;width:160px;background:#F8F9FF;">
                                <div slot="label" class="">
                                    <img src="/img/modales/camera.svg" alt="" />
                                </div>
                            </slim-cropper>
                            <p class="f-14 text-general2">Aspecto 4:5</p>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Aws from '~/services/aws'
import Categorias from "~/services/configurar/productos";

export default {
    data(){
        return {
            loading: false,
            model:{
                id: null,
                nombre: '',
                banner: '',
                adultos: false,
                descripcion: ''
            },
            slimOptions: {
                ratio: '4:5',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            titulo: '',
            editar: false,
        }
    },
    methods: {
        toggle(cat){
            if (cat){
                console.log(cat);
                this.model.id = cat.id
                this.model.nombre = cat.nombre
                this.model.descripcion = cat.descripcion
                this.model.adultos = parseInt(cat.adultos) == 1 ? true : false
                this.titulo = 'Editar categoría'
                this.editar = true
                if (cat.banner !== null){
                    this.$refs.cropCategoria.set_image(`${cat.banner_firmado}`)
                } else {
                    this.$refs.cropCategoria.instanciaCrop.remove()
                }
            } else {
                this.limpiar()
                this.titulo = 'Crear categoría'
                this.editar = false
            }
            this.$refs.crearCategoria.toggle()
        },
        async insertCategoria(){
            try {
                this.loading = true
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const archivo = this.$refs.cropCategoria.instanciaCrop.dataBase64.output

                // const url = await this.uploadCropperImage(archivo, 'categoria')
                this.model.banner = archivo.image
                if (this.editar){
                    const {data} = await Categorias.updateCategoria(this.model)
                    this.notificacion('Mensaje', 'Editado exitosamente', 'success')
                } else {
                    const {data} = await Categorias.insertCategoria(this.model)
                    this.notificacion('Mensaje', 'Creado exitosamente', 'success')
                }
                this.$emit('actualizar')
                this.limpiar()
                this.$refs.crearCategoria.toggle()
            } catch (e){
                this.error_catch(e)
            } finally{
                this.loading = false
            }
        },
        limpiar(){
            this.model = {
                id: null,
                nombre: '',
                banner: '',
                adultos: false,
                descripcion: '',
            }
            this.$refs.validacion.reset()
            this.$refs.cropCategoria.instanciaCrop.remove()
        }
    }
}
</script>
